/**
 * Chain enum - list supported chain names here.
 *
 * For now, lets base the list on SimpleHash's definition.
 * See Supported Chains & Testnets: https://docs.simplehash.com/reference/supported-chains-testnets
 */
export enum Chain {
  Bitcoin = "bitcoin",
  Ethereum = "ethereum",
}

export function asChain(enumValue: string): Chain | undefined {
  const chain: any = Chain;
  const keys = Object.keys(chain).filter((x) => chain[x] === enumValue);
  return keys.length > 0 ? chain[keys[0]] : undefined;
}

/**
 * Places using this function will need to be revisited if we add additional chains.
 * @param c
 */
export function ChainNot(c: Chain): Chain {
  return c === Chain.Bitcoin ? Chain.Ethereum : Chain.Bitcoin;
}
